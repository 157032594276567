import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import VOLogo from '../images/vo-logo.png';
import '../styles/nav.css';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: window.location.pathname,
            windowWidth: window.outerWidth,
            windowView: ''
        };
    }

    render() {
        const { currentPage } = this.state
        const { windowView } = this.props

        return (
            <Navbar className='vo-navbar'>
                <Navbar.Brand href="/">
                    <Image
                        src={VOLogo}
                        alt="Van Orden Realty Logo"
                        style={{ boxShadow: '0 0 8px rgba(0, 0, 0, .2)' }}
                        className={"vo-logo" + windowView}
                    />
                </Navbar.Brand>
                <Nav.Link className={'vo-nav-link' + windowView} href="/" style={currentPage === "/" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>
                    Main
                </Nav.Link>
                <Nav.Link className={'vo-nav-link' + windowView} href="/our-agents" style={currentPage === "/our-agents" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>
                    Our Agents
                </Nav.Link>
                <Nav.Link className={'vo-nav-link' + windowView} href="/apartment-rentals" style={currentPage === "/apartment-rentals" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>
                    Rentals
                </Nav.Link>
                <Nav.Link className={'vo-nav-link' + windowView} href="/homes-for-sale" style={currentPage === "/homes-for-sale" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>
                    Listings
                </Nav.Link>
                <Nav.Link className={'vo-nav-link' + windowView} href="/exclusive-listings" style={currentPage === "/exclusive-listings" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>Exclusives</Nav.Link>
                {/* <Nav.Link className={'vo-nav-link' + windowView} href="/lease-pdf" style={currentPage === "/lease-pdf" ? { marginTop: '.2rem', borderBottom: '.2rem solid #041b3a' } : null}>Lease Form</Nav.Link> */}
            </Navbar>
        );
    }
}

export default Navigation;
