import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: { lat: 40.947399, lng: -74.155512 },
            zoom: 15,
            windowWidth: window.outerWidth
        };
    };

    componentDidMount() {
        const { windowWidth } = this.state

        if (windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }
    }

    render() {
        const { windowView } = this.props

        return (
            <React.Fragment>
                {windowView === '-mobile' ?
                    <Map
                        google={this.props.google}
                        style={{ height: '100%', width: '85%', border: '1px solid #dcdcdc', borderRadius: '.25rem' }}
                        initialCenter={this.state.center}
                        zoom={this.state.zoom}>
                        <Marker name={'Current location'}></Marker>
                    </Map>
                    :
                    <Map
                        google={this.props.google}
                        style={{ height: '100%', width: '75%', border: '1px solid #dcdcdc', borderRadius: '.25rem' }}
                        initialCenter={this.state.center}
                        zoom={this.state.zoom}>
                        <Marker name={'Current location'}></Marker>
                    </Map>
                }
            </React.Fragment>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCP01Alaw9P1dQWP-WAKE2G1Dge83fMpiU'
})(MapContainer);

