import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

class PhotoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { currentPhotos, currentIndex, showModal } = this.props

        return (
            <div>
                <Modal show={showModal} onHide={() => this.props.onCloseModal()} size="lg" centered>
                    <Carousel activeIndex={currentIndex} interval={null} controls={false}>
                        {currentPhotos.map((photo, j) => (
                            <Carousel.Item key={photo} onClick={() => this.props.onClickModal(currentPhotos, currentIndex)}>
                                <Image style={{ display: 'block', width: '100%', maxHeight: '100%', height: 'auto' }} src={currentPhotos[currentIndex]} alt={"property-" + j} rounded />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal>
            </div >);
    }
}

export default PhotoModal;