import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import VOLogo from '../images/vo-logo.png';
import AddressIcon from '../images/footer-address-icon.png';
import PhoneIcon from '../images/footer-phone-icon.png';
import EmailIcon from '../images/footer-email-icon.png';
import MapContainer from './google-maps';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth
        };
    };

    componentDidMount() {
        const { windowWidth } = this.state

        if (windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }
    }

    render() {
        const { windowView } = this.props

        return (
            <div className='footer-container' style={{ width: '100%' }}>
                {windowView === '-mobile' ?
                    <Row className='footer' style={{ marginLeft: '1rem' }}>
                        <Col style={{ fontSize: '.875rem' }}>
                            <Row>
                                <Image src={VOLogo} alt="Van Orden Realty Logo" style={{ width: '10rem', height: '3rem', boxShadow: '0 0 8px rgba(0, 0, 0, .2)' }}></Image>
                            </Row>
                            <Row style={{ marginTop: '2rem', whitespace: 'nowrap' }}>
                                <Image src={AddressIcon} alt="Van Orden Realty Address Icon" style={{ float: 'left', width: '1.5rem', height: '1.5rem' }}></Image>
                                <p style={{ margin: '0 0 0 .5rem' }}>274 Lafayette Ave (Corner of Cedar Ave)<br />Hawthorne, NJ 07506</p>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <Image src={PhoneIcon} alt="Van Orden Realty Phone Icon" style={{ width: '1.5rem', height: '1.5rem' }}></Image>
                                <p style={{ margin: '0 0 0 .5rem' }}>(973) 427 - 1324</p>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <Image src={EmailIcon} alt="Van Orden Realty Email Icon" style={{ width: '1.5rem', height: '1.5rem' }}></Image>
                                <p style={{ margin: '0 0 0 .5rem' }}>GTVO@aol.com</p>
                            </Row>
                        </Col>
                        <Col>
                            <MapContainer windowView={windowView} />
                        </Col>
                    </Row>
                    :
                    <Row className='footer' style={{ width: '75%', margin: 'auto' }}>
                        <Col style={{ flex: 1.25 }}>
                            <div style={{ width: '60%', margin: 'auto' }}>
                                <Row>
                                    <Image src={VOLogo} alt="Van Orden Realty Logo" style={{ width: '10rem', height: '3rem', boxShadow: '0 0 8px rgba(0, 0, 0, .2)' }}></Image>
                                </Row>
                                <Row style={{ marginTop: '2rem', whitespace: 'nowrap' }}>
                                    <Image src={AddressIcon} alt="Van Orden Realty Address Icon" style={{ float: 'left', width: '1.5rem', height: '1.5rem' }}></Image>
                                    <p style={{ margin: '0 0 0 .5rem' }}>274 Lafayette Ave (Corner of Cedar Ave)<br />Hawthorne, NJ 07506</p>
                                </Row>
                                <Row style={{ marginTop: '1rem' }}>
                                    <Image src={PhoneIcon} alt="Van Orden Realty Phone Icon" style={{ width: '1.5rem', height: '1.5rem' }}></Image>
                                    <p style={{ margin: '0 0 0 .5rem' }}>(973) 427 - 1324</p>
                                </Row>
                                <Row style={{ marginTop: '1rem' }}>
                                    <Image src={EmailIcon} alt="Van Orden Realty Email Icon" style={{ width: '1.5rem', height: '1.5rem' }}></Image>
                                    <p style={{ margin: '0 0 0 .5rem' }}>GTVO@aol.com</p>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <MapContainer windowView={windowView} />
                        </Col>
                    </Row>
                }
                <div style={{ width: '100%', padding: '1.25rem 0', margin: 0, textAlign: 'center', color: '#fff', backgroundColor: '#002782' }}>
                    {windowView === '-mobile' ?
                        <Row style={{ textAlign: 'center', fontSize: '.875rem' }}>
                            <Col><a className="footer-nav-link" href="/">Main</a></Col>
                            <Col><a className="footer-nav-link" href="/our-agents">Our Agents</a></Col>
                            <Col><a className="footer-nav-link" href="/apartment-rentals">Rentals</a></Col>
                            <Col><a className="footer-nav-link" href="/homes-for-sale">Listings</a></Col>
                            <Col><a className="footer-nav-link" href="/exclusive-listings">Exclusives</a></Col>
                        </Row>
                        :
                        <Row style={{ width: '50%', margin: 'auto', textAlign: 'center', marginBottom: '2rem', fontSize: '.875rem' }}>
                            <Col><a className="footer-nav-link" href="/">Main</a></Col>
                            <Col><a className="footer-nav-link" href="/our-agents">Our Agents</a></Col>
                            <Col><a className="footer-nav-link" href="/apartment-rentals">Rentals</a></Col>
                            <Col><a className="footer-nav-link" href="/homes-for-sale">Listings</a></Col>
                            <Col><a className="footer-nav-link" href="/exclusive-listings">Exclusives</a></Col>
                        </Row>
                    }
                    <p style={{ margin: '2rem 0 0 0', fontSize: '1rem', fontWeight: '500' }}>VAN ORDEN REALTY | 2023</p>
                    <p style={{ margin: '.5rem 0 0 0', fontSize: '.725rem' }}>© All Rights Reserved</p>
                </div>
            </div >
        );
    }
}

export default Footer;