import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Navigation from './navbar';
import Footer from './footer';
import '../styles/apartment-rentals.css';

class Exclusives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
            windowView: ''
        }
    }

    componentDidMount() {
        const { windowWidth, windowHeight } = this.state

        if (windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }

        if (windowHeight === 0) {
            this.setState({ windowHeight: window.innerHeight })
        }

        if (window.outerWidth < 1024 || window.innerWidth < 1024) {
            this.setState({ windowView: '-mobile' })
        }
    }

    render() {
        const { windowWidth, windowView } = this.state

        return (
            <div style={{ width: '100%' }}>
                <Navigation windowView={windowView} />
                <div className={'off-main-background' + windowView}>
                    <div>
                        <h1 className={"page-title" + windowView}>EXCLUSIVE LISTINGS</h1>
                    </div>
                </div>
                <p className={'no-properties' + windowView} style={windowWidth < 1024 ? { fontSize: '1.5rem' } : { fontSize: '2rem' }}>There are no exclusive listings available at this time.</p>
                <Footer windowView={windowView} />
                <Helmet>
                    <title>Van Orden Realty - Exclusive Listings</title>
                    <meta name="description" content="A complete list of exclusive listings offered by Van Orden Realty" />
                </Helmet>
            </div>
        );
    }
}

export default Exclusives;