import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Navigation from './navbar';
import Footer from './footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Gregg from '../images/gregg.png';
import Bob from '../images/bob.png';
import '../styles/our-agents.css';

class OurAgents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth,
            windowView: ''
        };
    }

    componentDidMount() {
        if (this.state.windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }

        if (window.outerWidth < 1024 || window.innerWidth < 1024) {
            this.setState({ windowView: '-mobile' })
        }
    }

    render() {
        const { windowView } = this.state

        return (
            <div style={{ width: '100%' }}>
                <Navigation windowView={windowView} />
                <div className={'off-main-background' + windowView}>
                    <div>
                        <h1 className={"page-title" + windowView}>OUR AGENTS</h1>
                        <h3 className={"page-subtitle" + windowView}>Gregg Van Orden | Bob Lucibello</h3>
                    </div>
                </div>
                <Row className="agent-row">
                    <div className={"agent-container" + windowView}>
                        <Col>
                            <Image className="agent-photo" src={Gregg} alt="Gregg T. Van Orden" />
                        </Col>
                        <Col>
                            <h1 className="agent-name">Gregg T. Van Orden</h1>
                            <p className="agent-title">Broker of Record</p>
                            <p className={"agent-description" + windowView}>
                                Gregg began his career working with his father, Don Van Orden, and brings 40+ years experience into the Real Estate profession.
                                <br />
                                <br />
                                He has accumulated an extensive knowledge base of the industry, local geography, and property valuation which he's levereged to aid thousands of clients over the years.
                                <br />
                                <br />
                                In an ever increasing competitive market, Gregg has attained an adept ability at striking balance between buyers and sellers; an art rare among fellow agents. He will put in the extra effort when it counts and with the addition of Bob Lucibello, Van Orden Realty brings 85+ years Real Estate experience to the table, with a partnership qualified to assist in all of your Real Estate needs.
                            </p>
                        </Col>
                    </div>
                </Row>
                <Row className="agent-row">
                    <div className={"agent-container" + windowView}>
                        <Col>
                            <Image className="agent-photo" src={Bob} alt="Bob Lucibello"></Image>
                        </Col>
                        <Col>
                            <h1 className="agent-name">Robert J. Lucibello</h1>
                            <p className="agent-title">Broker</p>
                            <p className="agent-description">
                                Bob Lucibello is a lifelong resident of Hawthorne, NJ and has been listing and selling real estate in the Bergen/Passaic County area for over 50 years.
                                <br />
                                <br />
                                His experience and dedication has earned him the title REALTOR EMERTIS, granted by the National Association of Realtors.
                                <br />
                                <br />
                                He is also the Vice Chairman of the Hawthorne Planning Board and is on the Board of Directors of the Hawthorne Chamber of Commerce. Bob has been named a FIVE STAR REAL ESTATE AGENT by New Jersey Monthly magazine for the past four years.
                            </p>
                        </Col>
                    </div>
                </Row>
                <Footer windowView={windowView} />
                <Helmet>
                    <title>Van Orden Realty - Our Agents</title>
                    <meta name="description" content="Our agents have the skill and experience needed to quickly rent or sell your property in Hawthorne NJ and surrounding areas." />
                </Helmet>
            </div>
        );
    }
}

export default OurAgents;