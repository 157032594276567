import React, { Component } from 'react';
import RentalIcon from '../../images/for-rent-icon.png';
import SaleIcon from '../../images/for-sale-icon.png';
import ExclusiveIcon from '../../images/exclusive-icon.png';
import NoneAvailable from '../../images/none-available.png';
import RentalSlider from './rental-slider';
import ListingSlider from './listing-slider';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class MainCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth
        };

        this.setRentalButton = this.setRentalButton.bind(this);
        this.setListingButton = this.setListingButton.bind(this);
        this.setExclusiveButton = this.setExclusiveButton.bind(this);
    };

    setRentalButton() {
        if (this.state.windowWidth <= 1024) {
            return 'View Rentals'
        }
        else {
            return 'View Available Rentals'
        }
    }

    setListingButton() {
        if (this.state.windowWidth <= 1024) {
            return 'View Listings'
        }
        else {
            return 'View Available Listings'
        }
    }

    setExclusiveButton() {
        if (this.state.windowWidth <= 1024) {
            return 'View Exclusives'
        }
        else {
            return 'View Available Exclusives'
        }
    }

    render() {
        const { rentalPhotos, listingPhotos, windowView } = this.props

        return (
            <div>
                {windowView === '-mobile' ?
                    <Row style={{ margin: '2rem 1rem' }}>
                        <Row>
                            <Card className="main-card" style={{ width: '60%' }}>
                                {rentalPhotos.length > 0 ?
                                    <RentalSlider rentalPhotos={rentalPhotos} /> :
                                    <div>
                                        <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO RENTALS AVAILABLE</p>
                                        <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Listings Available"></Card.Img>
                                    </div>
                                }
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={RentalIcon} alt="Van Orden Realty Rental Icon 1" />
                             Rentals
                             <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={RentalIcon} alt="Van Orden Realty Rental Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available apartments for rent in and around the Hawthorne NJ area.
                                    </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/apartment-rentals"><Button variant="primary" className='main-card-button'>{this.setRentalButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: '2.5rem' }}>
                            <Card className="main-card" style={{ width: '60%' }}>
                                {listingPhotos.length > 0 ?
                                    <ListingSlider listingPhotos={listingPhotos} /> :
                                    <div>
                                        <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO LISTINGS AVAILABLE</p>
                                        <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Listings Available"></Card.Img>
                                    </div>
                                }
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={SaleIcon} alt="Van Orden Realty Listing Icon 1" />
                             Listings
                             <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={SaleIcon} alt="Van Orden Realty Listing Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available homes for sale in and around the Hawthorne NJ area.
                                    </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/homes-for-sale"><Button variant="primary" className='main-card-button'>{this.setListingButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: '2.5rem' }}>
                            <Card className="main-card" style={{ width: '60%' }}>
                                <div>
                                    <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO EXCLUSIVES AVAILABLE</p>
                                    <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Exclusives Available"></Card.Img>
                                </div>
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={ExclusiveIcon} alt="Van Orden Realty Exclusive Icon 1" />
                             Exclusives
                             <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={ExclusiveIcon} alt="Van Orden Realty Exclusive Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available exclusives for sale in and around the Hawthorne NJ area.
                                    </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/exclusive-listings"><Button variant="primary" className='main-card-button'>{this.setExclusiveButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Row>
                    :
                    <Row style={{ margin: '4rem 2rem' }}>
                        <Col>
                            <Card className="main-card" style={{ width: '75%' }}>
                                {rentalPhotos.length > 0 ?
                                    <RentalSlider rentalPhotos={rentalPhotos} /> :
                                    <div>
                                        <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO RENTALS AVAILABLE</p>
                                        <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Listings Available"></Card.Img>
                                    </div>
                                }
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={RentalIcon} alt="Van Orden Realty Rental Icon 1" />
                                Rentals
                                <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={RentalIcon} alt="Van Orden Realty Rental Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available apartments for rent in and around the Hawthorne NJ area.
                        </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/apartment-rentals"><Button variant="primary" className='main-card-button'>{this.setRentalButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="main-card" style={{ width: '75%' }}>
                                {listingPhotos.length > 0 ?
                                    <ListingSlider listingPhotos={listingPhotos} /> :
                                    <div>
                                        <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO LISTINGS AVAILABLE</p>
                                        <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Listings Available"></Card.Img>
                                    </div>
                                }
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={SaleIcon} alt="Van Orden Realty Listing Icon 1" />
                                Listings
                                <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={SaleIcon} alt="Van Orden Realty Listing Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available homes for sale in and around the Hawthorne NJ area.
                        </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/homes-for-sale"><Button variant="primary" className='main-card-button'>{this.setListingButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="main-card" style={{ width: '75%' }}>
                                <div>
                                    <p style={{ position: 'absolute', top: '.75rem', width: '100%', textAlign: 'center', color: '#555555' }}>NO EXCLUSIVES AVAILABLE</p>
                                    <Card.Img style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.125)' }} src={NoneAvailable} alt="No Exclusives Available"></Card.Img>
                                </div>
                                <Card.Body>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Card.Title style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                            <Image style={{ width: '1.25rem', marginRight: '1rem' }} src={ExclusiveIcon} alt="Van Orden Realty Exclusive Icon 1" />
                                Exclusives
                                <Image style={{ width: '1.25rem', marginLeft: '1rem' }} src={ExclusiveIcon} alt="Van Orden Realty Exclusive Icon 2" />
                                        </Card.Title>
                                    </div>
                                    <Card.Text style={{ margin: '1.5rem 0', textAlign: 'justify' }}>
                                        Click the link below for a detailed view of all our available exclusives for sale in and around the Hawthorne NJ area.
                        </Card.Text>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <a href="/exclusive-listings"><Button variant="primary" className='main-card-button'>{this.setExclusiveButton()}</Button></a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

export default MainCards;