import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Navigation from './navbar';
import Footer from './footer';
import PhotoModal from '../components/photo-modal';
import '../styles/apartment-rentals.css';

class ApartmentRentals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numbers: [],
            headers: [],
            highlights: [],
            features: [],
            histories: [],
            photos: [],
            towns: null,
            current: { id: null, shown: false },
            currentPhotos: [],
            currentIndex: 0,
            showModal: false,
            windowView: '',
            windowWidth: window.outerWidth,

        };

        this.setShowTabs = this.setShowTabs.bind(this)
        this.onShowModal = this.onShowModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onClickModal = this.onClickModal.bind(this)
    }

    componentDidMount() {
        if (this.state.windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }

        if (window.outerWidth < 1024 || window.innerWidth < 1024) {
            this.setState({ windowView: '-mobile' })
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Access-Control-Allow-Origin': '*' }
        };

        fetch('https://secure.savingsbondsolutions.com/rentals', requestOptions)
            .then(response => response.json())
            .then(response => {
                // console.log(JSON.stringify(response))
                // let towns = []

                let numbers = []
                let headers = []
                let highlights = []
                let features = []
                let histories = []
                let photos = []




                for (let key in response) {
                    // let town = response[key][1][1][1]

                    // if (towns.indexOf(town) === -1) {
                    //     towns.push(town)
                    // }

                    numbers.push(key)
                    headers.push(response[key][0])
                    highlights.push(response[key][1])
                    features.push(response[key][2])
                    histories.push(response[key][3])
                    photos.push(response[key][4])
                }

                this.setState({
                    numbers: numbers,
                    headers: headers,
                    highlights: highlights,
                    features: features,
                    histories: histories,
                    photos: photos
                    // towns: towns.join(" | ")
                })

            })
    }

    onShowModal(i, number, j) {
        this.setState({ showModal: true, currentPhotos: this.state.photos[i][number], currentIndex: j })
    }

    onClickModal(currentPhotos, currentIndex) {
        if ((currentIndex + 1) < currentPhotos.length) {
            this.setState({ currentIndex: currentIndex + 1 })
        }
        else {
            this.setState({ currentIndex: 0 })
        }
    }


    onCloseModal() {
        this.setState({ showModal: false, currentIndex: 0 })
    }

    setShowTabs(e) {
        const { current } = this.state

        let targetID = e.target.id
        let targetValue = ((e.target.innerHTML).split(' ')[0]).toLowerCase()

        if ((targetID !== current.id || targetID === current.id) && targetValue === 'show') {
            this.setState({ current: { id: targetID, value: true } })
        }
        else {
            this.setState({ current: { id: targetID, value: false } })
        }
    }

    render() {
        const { numbers, headers, highlights, features, histories, photos, currentPhotos, currentIndex, towns, current, windowWidth, windowView, showModal } = this.state

        return (
            <div style={{ width: '100%' }}>
                <Navigation windowView={windowView} />
                <div className={'off-main-background' + windowView}>
                    <div>
                        <h1 className={"page-title" + windowView}>APARTMENTS FOR RENT</h1>
                        <h3 className={"page-subtitle" + windowView}>{towns}</h3>
                    </div>
                </div>
                <div style={{ marginTop: '2rem' }}>
                    {numbers.length > 0 ?
                        numbers.map((number, i) => (
                            <div key={number} className={"property-container" + windowView} style={windowView === '' ? { minWidth: (windowWidth * .8) } : null}>
                                {windowView === '' ?
                                    <Row style={{ position: 'relative', width: '95%', margin: 'auto' }}>
                                        <Col style={{ padding: 0 }}>
                                            {photos.length > 0 ?
                                                <div style={{ width: '100%' }}>
                                                    <Carousel interval={null}>
                                                        {photos[i][number].map((photo, j) => (
                                                            <Carousel.Item key={photo}>
                                                                <Image onClick={() => this.onShowModal(i, number, j)} style={{ display: 'block', maxWidth: '100%', maxHeight: '20rem', width: 'auto', height: 'auto', margin: 'auto' }} src={photo} alt={"property-" + j} rounded />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                : null}
                                        </Col>
                                        <PhotoModal currentPhotos={currentPhotos} currentIndex={currentIndex} showModal={showModal} onClickModal={this.onClickModal} onCloseModal={this.onCloseModal} />
                                        <Col style={{ flex: 1.5, marginLeft: '2rem' }}>
                                            {headers.length > 0 ?
                                                <div>
                                                    <Row>
                                                        <Col style={{ flex: .75, padding: 0 }}>
                                                            <Badge variant="danger" style={{ padding: '.75rem', fontWeight: '500', background: '#f34a4a' }}>FOR RENT</Badge>
                                                        </Col>
                                                        <Col style={{ flex: 2, padding: 0, marginLeft: '.25rem' }}>
                                                            <Button id={number} variant="primary" className='rental-info-button' onClick={this.setShowTabs}>{number === current.id && current.value ? 'Hide Property Details' : 'Show Property Details'}</Button>
                                                        </Col>
                                                        <Col style={{ textAlign: 'right', padding: 0 }}>
                                                            <p className="rental-price">
                                                                {headers[i][number][0]}
                                                                <span style={{ fontSize: '1.25rem' }}>/mo</span>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <p className="rental-address" style={{ margin: '1rem 0', fontSize: '1.25rem', fontWeight: '500' }}>{headers[i][number][1]}</p>
                                                    </Row>
                                                    <Row>
                                                        <p className="rental-description" style={{ textAlign: 'justify' }}>{headers[i][number][2]}</p>
                                                    </Row>
                                                </div>
                                                : null}
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={{ position: 'relative', width: '95%', margin: 'auto' }}>
                                        {photos.length > 0 ?
                                            <div style={{ cursor: 'pointer' }}>
                                                <Carousel interval={null}>
                                                    {photos[i][number].map((photo, i) => (
                                                        <Carousel.Item key={photo}>
                                                            <Image style={{ display: 'block', maxWidth: '100%', maxHeight: '15rem', width: 'auto', height: 'auto', margin: 'auto' }} src={photo} alt={"property-" + i} rounded />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </div>
                                            : null}
                                        <Col style={{ position: 'relative', top: 0, left: 0, textAlign: 'right', padding: 0 }}>
                                            <Badge variant="danger" style={{ float: 'left', padding: '.75rem', marginLeft: '1rem', fontWeight: '500', background: '#f34a4a' }}>FOR RENT</Badge>
                                            <p className="rental-price">
                                                {headers[i][number][0]}
                                                <span style={{ fontSize: '1.25rem' }}>/mo</span>
                                            </p>
                                            <Button id={number} variant="primary" className='rental-info-button-mobile' onClick={this.setShowTabs}>{number === current.id && current.value ? 'Hide Property Details' : 'Show Property Details'}</Button>
                                        </Col>
                                        {/* <div>
                                            {headers.length > 0 ?
                                                headers[i][number].map((header) => (
                                                    <div key={header}>
                                                        <div>
                                                            <p className="rental-address" style={{ margin: '1rem 0', fontSize: '1.25rem', fontWeight: '500' }}>{header[1]}</p>
                                                            <p className="rental-description" style={{ textAlign: 'justify' }}>{header[2]}</p>
                                                        </div>
                                                    </div>
                                                )) : null}
                                        </div> */}
                                        <div>
                                            <p className="rental-address" style={{ margin: '1rem 0', fontSize: '1.25rem', fontWeight: '500' }}>{headers[i][number][1]}</p>
                                            <p className="rental-description" style={{ textAlign: 'justify' }}>{headers[i][number][2]}</p>
                                        </div>
                                    </Row>
                                }
                                {
                                    number === current.id && current.value ?
                                        <div style={windowView === '' ? { padding: '0 2.5rem', paddingTop: '2rem' } : { padding: '0 1rem', paddingTop: '2rem' }}>
                                            <Tabs className='property-tabs' defaultActiveKey="property-details" transition={false}>
                                                <Tab eventKey="property-details" title="PROPERTY DETAILS" style={{ padding: '.5rem .5rem' }}>
                                                    {highlights.length > 0 ?
                                                        <Row>
                                                            <Col style={{ marginLeft: '1rem', flex: 1.125 }}>
                                                                <ListGroup className="rental-list-group">
                                                                    {highlights[i][number].slice(0, 4).map((highlight) => (
                                                                        <ListGroup.Item key={highlight} style={{ border: 'none' }}>
                                                                            <p style={{ fontWeight: '600', textTransform: 'capitalize' }}>{highlight[0]}</p>
                                                                            <p>{highlight[1]}</p>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Col>
                                                            <Col style={{ flex: 0.875 }}>
                                                                <ListGroup className="rental-list-group" style={{ float: 'left' }}>
                                                                    {highlights[i][number].slice(4, 8).map((highlight) => (
                                                                        <ListGroup.Item key={highlight} style={{ border: 'none' }}>
                                                                            <p style={{ fontWeight: '600', textTransform: 'capitalize' }}>{highlight[0]}</p>
                                                                            <p>{highlight[1]}</p>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Col>
                                                            <Col>
                                                                <ListGroup className="rental-list-group" style={{ float: 'left' }}>
                                                                    {highlights[i][number].slice(8, 12).map((highlight) => (
                                                                        <ListGroup.Item key={highlight} style={{ border: 'none' }}>
                                                                            <p style={{ fontWeight: '600', textTransform: 'capitalize' }}>{highlight[0]}</p>
                                                                            <p>{highlight[1]}</p>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                        : null}
                                                </Tab>
                                                <Tab eventKey="property-features" title="PROPERTY FEATURES" style={{ padding: '.5rem 1rem' }}>
                                                    {features.length > 0 ?
                                                        <Row>
                                                            <Col style={{ marginLeft: '1rem' }}>
                                                                <ListGroup className="rental-list-group">
                                                                    {features[i][number].slice(0, 4).map((feature) => (
                                                                        <ListGroup.Item key={feature} style={{ border: 'none' }}>
                                                                            <p style={{ fontWeight: '600' }}>{feature[0]}</p>
                                                                            <p>{feature[1]}</p>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Col>
                                                            <Col style={{ marginLeft: '1rem' }}>
                                                                <ListGroup className="rental-list-group">
                                                                    {features[i][number].slice(4, 8).map((feature) => (
                                                                        <ListGroup.Item key={feature} style={{ border: 'none' }}>
                                                                            <p style={{ fontWeight: '600' }}>{feature[0]}</p>
                                                                            <p>{feature[1]}</p>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Col>
                                                        </Row> : null}
                                                </Tab>
                                                <Tab eventKey="property-history" title="PROPERTY HISTORY" style={{ padding: '.5rem 1rem' }}>
                                                    {histories.length > 0 ?
                                                        <Table striped>
                                                            <thead style={{ fontWeight: '500', textAlign: 'center' }}>
                                                                <tr>
                                                                    <th style={{ border: 'none' }}>DATE</th>
                                                                    <th style={{ border: 'none' }}>PRICE</th>
                                                                    <th style={{ border: 'none' }}>STATUS</th>
                                                                    <th style={{ border: 'none' }}>AGENCY</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {histories[i][number].map((history) => (
                                                                    <tr key={history} style={{ textAlign: 'center' }}>
                                                                        <td style={{ borderTop: 'none' }}>{history[0]}</td>
                                                                        <td style={{ borderTop: 'none' }}>{history[1]}</td>
                                                                        <td style={{ borderTop: 'none' }}>{history[2]}</td>
                                                                        <td style={{ borderTop: 'none' }}>{history[3].replace("By: ", "")}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        : null}
                                                </Tab>
                                            </Tabs>
                                        </div> : null
                                }
                            </div>
                        )) : null
                    }
                </div>
                <Footer windowView={windowView} />
                <Helmet>
                    <title>Van Orden Realty - Apartments For Rent</title>
                    <meta name="description" content="A complete lists of our available apartments for rent in Hawthorne NJ and surrounding towns." />
                </Helmet>
            </div >
        );
    }
}

export default ApartmentRentals;