import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ListGroup from 'react-bootstrap/ListGroup';
import Navigation from './navbar';
import MainCards from './main/main-cards';
import Footer from './footer';
import '../styles/main.css';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rentalPhotos: [],
            listingPhotos: [],
            windowWidth: window.outerWidth,
            windowView: ''
        };

        this.handleResize = this.handleResize.bind(this)
    };

    componentDidMount() {
        const { windowWidth } = this.state

        if (windowWidth === 0) {
            this.setState({ windowWidth: window.innerWidth })
        }

        if (window.outerWidth < 1024 || window.innerWidth < 1024) {
            this.setState({ windowView: '-mobile' })
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Access-Control-Allow-Origin': '*' }
        };

        //'http://localhost:5000/main-rentals-slider'
        //'https://secure123.vanordenrealty.com/main-rentals-slider'

        fetch('https://secure123.vanordenrealty.com/main-rentals-slider', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({ rentalPhotos: response })
            })

        //'http://localhost:5000/main-listings-slider'
        //'https://secure123.vanordenrealty.com/main-listings-slider'

        fetch('https://secure123.vanordenrealty.com/main-listings-slider', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({ listingPhotos: response })
            })
    }

    handleResize() {
        setTimeout(() => {
            const { windowWidth } = this.state

            if (windowWidth === 0) {
                this.setState({ windowWidth: window.innerWidth })
            }
            else {
                this.setState({ windowWidth: window.outerWidth })
            }

        }, 25);
    }

    render() {
        const { rentalPhotos, listingPhotos, windowView } = this.state

        return (
            <div style={{ width: '100%' }}>
                <Navigation windowView={windowView} />
                <Jumbotron>
                    <h3 className={'main-title' + windowView}>50+ Years Experience in the Hawthorne, NJ area.</h3>
                    <p className={'main-subtitle' + windowView}>SPECIALIZING IN:</p>
                    <ListGroup style={{ width: '20rem', marginTop: '2rem', textTransform: 'uppercase' }}>
                        <ListGroup.Item className={'main-list-item' + windowView}><a href="/apartment-rentals" style={{ color: 'rgb(255, 66, 14)' }}>Apartment Rentals</a></ListGroup.Item>
                        <ListGroup.Item className={'main-list-item' + windowView}><a href="/homes-for-sale" style={{ color: 'rgb(255, 66, 14)' }}>Home Sales</a></ListGroup.Item>
                        <ListGroup.Item className={'main-list-item' + windowView} style={{ color: 'rgb(255, 66, 14)' }}>Property Management</ListGroup.Item>
                    </ListGroup>
                </Jumbotron>
                <Row style={{ width: '85%', margin: 'auto', marginTop: '2rem', textAlign: 'justify' }}>
                    <Row><h1 style={{ fontSize: '2rem' }}>VAN ORDEN REALTY</h1></Row>
                    <Row><p style={{ marginTop: '1rem' }}>Established in 1960, Van Orden Realty has provided Hawthorne, NJ and neighboring townships with high quality service and loyalty since our founding year. We are proud to operate with a high degree of integrity toward anyone who reaches out for information, to buy, to sell, or to rent. Our goal with every deal in a competitive market, is to strike a balance between buyer and seller.</p></Row>
                </Row>
                <MainCards rentalPhotos={rentalPhotos} listingPhotos={listingPhotos} windowView={windowView} />
                <Footer windowView={windowView} />
                <Helmet>
                    <title>Van Orden Realty</title>
                    <meta name="description" content="With 80+ years combined real estate experience in Hawthorne NJ, Van Orden Realty has the skills you need to rent or sell your property. Visit us today!" />
                </Helmet>
            </div >
        );
    }
}

export default Main;