import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';

class ListingSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { listingPhotos } = this.props

        return (
            <Carousel
                fade={true}
                pause={false}
                interval={4500}
                controls={false}
                indicators={false}
            >
                {listingPhotos.map((photo, i) => (
                    <Carousel.Item className="main-slider" key={photo}>
                        <Image
                            style={{ display: 'block', maxWidth: '100%', maxHeight: '20rem', width: 'auto', height: 'auto', margin: 'auto' }}
                            src={photo}
                            alt={"Hawthorne NJ Homes and Apartment Listings " + i}
                            rounded
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }
}

export default ListingSlider;