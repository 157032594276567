import React, { Component } from 'react';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

class LeasePDF extends Component {
    state = {
    }

    componentDidMount() {
        var button = document.createElement('button');
        button.innerHTML = 'click me';
        button.onclick = function () {
            async function createPdf() {
                const pdfDoc = await PDFDocument.create()
                const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

                const page = pdfDoc.addPage()
                const { width, height } = page.getSize()
                const fontSize = 30
                page.drawText('Creating PDFs in JavaScript is awesome!', {
                    x: 50,
                    y: height - 4 * fontSize,
                    size: fontSize,
                    font: timesRomanFont,
                    color: rgb(0, 0.53, 0.71),
                })

                const pdfBytes = await pdfDoc.save()
            }

        };

        document.getElementById('test').appendChild(button);
    }

    render() {
        return (
            <div id="test">
            </div>
        );
    }
}

export default LeasePDF;