import React, { Component } from 'react';
import Main from './components/main';
import ApartmentRentals from './components/apartment-rentals';
import OurAgents from './components/our-agents';
import Listings from './components/homes-for-sale';
import Exclusives from './components/exclusive-listings';
import LeasePDF from './components/lease-pdf';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import PhoneIcon from './images/phone-icon.png';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ height: '3rem', width: '100%', backgroundColor: '#f7f7f7' }}>
          <div style={{ width: '10rem', position: 'absolute', top: '.75rem', right: 0 }}>
            <Image src={PhoneIcon} alt="phone-icon" style={{ float: 'left', width: '.75rem', marginTop: '.1rem', marginRight: '.5rem' }}></Image>
            <p style={{ fontSize: '.875rem' }}>(973) 427 - 1324</p>
          </div>
        </div>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Main} />
            <Route exact path='/our-agents' component={OurAgents} />
            <Route exact path='/apartment-rentals' component={ApartmentRentals} />
            <Route exact path='/homes-for-sale' component={Listings} />
            <Route exact path='/exclusive-listings' component={Exclusives} />
            {/* <Route exact path='/lease-pdf' component={LeasePDF} /> */}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
